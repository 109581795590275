import counterReducer from './features/counter/counterSlice';
import companyReducer from './redux/slices/company';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { cartReducer } from './redux/slices/cartSlice';
import { categoryReducer } from './redux/slices/categorySlice';
import { productReducer } from './redux/slices/productSlice';
import { timeslotReducer } from './redux/slices/timeslotSlice';
import { homecatReducer } from './redux/slices/homecatSlice';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import { baseurlReducer } from './redux/slices/baseUrlSlice';
import { coordinateReducer } from './redux/slices/coordinateSlice';
import { brandReducer } from './redux/slices/brandSlice';
import { sitemapReducer } from './redux/slices/sitemapSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['products', 'category', 'timeslot', 'homecat'],
};

const reducer = combineReducers({
  counter: counterReducer,
  company: companyReducer,
  cart: cartReducer,
  category: categoryReducer,
  products: productReducer,
  timeslot: timeslotReducer,
  homecat: homecatReducer,
  baseurl: baseurlReducer,
  coordinate:coordinateReducer,
  brand:brandReducer,
  sitemap:sitemapReducer
});

//const persistedReducer = persistReducer(persistConfig,reducer);
export const store = configureStore({
  reducer: reducer, //persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      mmutableCheck: false,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
