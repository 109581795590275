// MapComponent.js
import React, { useState, useRef,useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Button,Stack,Flex,Input,InputGroup,InputRightElement,Divider  } from '@chakra-ui/react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { PhoneIcon } from '@chakra-ui/icons'
import { BiCurrentLocation } from "react-icons/bi";
import { useDispatch,useSelector } from 'react-redux';
import "./Basic.css";
const containerStyle = {
  width: "100%",
  height: "200px",
};
const center = {
  lat: 0,
  lng: 0,
};
const MapComponent = ({register,setValue,appgoogID,seladdress}) => {
  const [map, setMap] = useState(null);
  const [address, setAddress] = useState();
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [markerAddress, setMarkerAddress] = useState("");
  const base_url = useSelector(state => state.baseurl.baseURL);
  const companysetting  = useSelector((state) => state.company);
  const shipping_city_array = companysetting.length > 0 && companysetting[0].DATA.shipping_city.length > 0 ? companysetting[0].DATA.shipping_city : "";
  const markerRef = useRef(null);
  const searchOptions = {
    componentRestrictions: { 
      country: ['in']
     },
  }
  useEffect(() => {
    setAddress(seladdress);
  }, [seladdress]);
  const onMapLoad = (map) => {
    setMap(map);
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
    setValue('address1',e.target.value);
  };
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      //console.log("GeoLocal");console.log(results);
      if (status === "OK" && results[0]) {
        setMarkerAddress(results[0].formatted_address);
        setValue('latitude',lat);
        setValue('longitude',lng);
      } else {
        setMarkerAddress("Address not found");
      }
    });
  };
  const onAddressSelect = async (address) => {
    try {
      setAddress(address);
      setValue('address1',address);
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ lat, lng });
      reverseGeocode(lat, lng);
      if (map) {
        map.panTo({ lat, lng }); 
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
    }
  };
  return (
    <div>
      <Flex mb={4}>
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={onAddressSelect} searchOptions={searchOptions}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <InputGroup>
                <InputRightElement pointerEvents='none'>
                  <BiCurrentLocation color='gray.300' />
                </InputRightElement>
                <Input
                {...getInputProps({
                  placeholder: 'Search the address where the delivery is required',
                })}
                size='sm'
                bg={'gray.100'} 
                w={['300px', '450px', '620px']}
                type="text"
              />
              </InputGroup>
              <div className="suggestions-container">
                {loading && <div>Loading...</div>}
                {suggestions
                .filter((suggestion) => {
                  if(shipping_city_array .length > 0){
                    const citiesToInclude = shipping_city_array;
                    return suggestion.terms.some((term) => citiesToInclude.includes(term.value)); 
                  }
                  return true;
                })
                .map((suggestion, index) => {
                  const style = suggestion.active
                    ? { backgroundColor: '#e5e5e5', cursor: 'pointer' }
                    : { backgroundColor: '#fffef2', cursor: 'pointer' };
                  return (
                    <div
                      className="suggestions-inner"
                      key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                      <Divider />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Flex>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={20}
          onLoad={onMapLoad}
        >
          {coordinates.lat && coordinates.lng && (
            <Marker
              position={{ lat: coordinates.lat, lng: coordinates.lng }}
              draggable={true}
              onDragEnd={(e) => {
                const newPosition = e.latLng.toJSON();
                setCoordinates({ lat: newPosition.lat, lng: newPosition.lng });
                reverseGeocode(newPosition.lat, newPosition.lng);
              }}
            />
          )}
        </GoogleMap>
    </div>
  );
};

export default MapComponent;