
function PaymentResponse() {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    //console.log("res status:");console.log(status);
    const res = JSON.parse(status);
    //console.log("res: ", res);
    return (
        <div className="App row align-items-center justify-content-center">
          <div className="col-md-6 shadow py-2">
            <h3>Payment Response</h3>
            <div className="row"><label className="col-md-4"> resp_message: </label> <div className="col-md-7"> {res?.resp_message}  </div> </div>
            <div className="row"><label className="col-md-4"> bank_ref_id: </label> <div className="col-md-7"> {res.bank_ref_id}  </div> </div>
            <div className="row"><label className="col-md-4"> cust_email_id: </label> <div className="col-md-7"> {res?.cust_email_id}  </div> </div>
            <div className="row"><label className="col-md-4"> cust_mobile_no: </label> <div className="col-md-7"> {res?.cust_mobile_no}  </div> </div>
            <div className="row"><label className="col-md-4"> merchant_id: </label> <div className="col-md-7"> {res?.merchant_id}  </div> </div>
            <div className="row"><label className="col-md-4"> payment_mode: </label> <div className="col-md-7"> {res?.payment_mode}  </div> </div>
            <div className="row"><label className="col-md-4"> pg_ref_id: </label> <div className="col-md-7"> {res?.pg_ref_id}  </div> </div>
            <div className="row"><label className="col-md-4"> resp_code: </label> <div className="col-md-7"> {res?.resp_code}  </div> </div>
            <div className="row"><label className="col-md-4"> resp_date_time: </label> <div className="col-md-7"> {res?.resp_date_time}  </div> </div>
            <div className="row"><label className="col-md-4"> trans_status: </label> <div className="col-md-7"> {res?.trans_status}  </div> </div>
            <div className="row"><label className="col-md-4"> txn_amount: </label> <div className="col-md-7"> {res?.txn_amount}  </div> </div>
            <div className="row"><label className="col-md-4"> txn_date_time: </label> <div className="col-md-7"> {res?.txn_date_time}  </div> </div>
            <div className="row"><label className="col-md-4"> txn_id: </label> <div className="col-md-7"> {res?.txn_id}  </div> </div>
            <div className="row"><label className="col-md-4"> udf1: </label> <div className="col-md-7"> {res?.udf1}  </div> </div>
            <div className="row"><label className="col-md-4"> udf2: </label> <div className="col-md-7"> {res?.udf2}  </div> </div>
            <div className="row"><label className="col-md-4"> udf3: </label> <div className="col-md-7"> {res?.udf3}  </div> </div>
            <div className="row"><label className="col-md-4"> udf4: </label> <div className="col-md-7"> {res?.udf4}  </div> </div>
            <div className="row"><label className="col-md-4"> udf5: </label> <div className="col-md-7"> {res?.udf5}  </div> </div>
          </div>
        </div>
    );
}
export default PaymentResponse;
    