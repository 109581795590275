import React,{ useEffect } from 'react'
import {
    Heading,
    Text,
    Container,
    VStack,
  } from '@chakra-ui/react';
import DynamicButton from './DynamicButton'
import { useSelector,useDispatch } from 'react-redux'
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import {changeCategoryLabel} from '../redux/slices/categorySlice';
import { addSiteMap } from '../redux/slices/sitemapSlice';
import http from '../http-common';

const TermdandCondition = () => {
  document.title = 'Home/Terms and Conditions';
  const dispatch = useDispatch();
  const companysetting  = useSelector((state) => state.company);
  const basesitemaparr = useSelector(state => state.sitemap.sitemap);

  useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(changeCategoryLabel(""));
    const spinner = document.getElementById("spinner");
    if(spinner){
      setTimeout(() => {
        spinner.style.display = "none";
      }, 1500);
    }
  },['']);

  async function getSitemapData(current_url){
    let url = '/ecommerce_api/saveSitemap/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        url: current_url,
      })
      .then(res => {
        if(res.data[0].STATUS == 'SUCCESS'){
          dispatch(addSiteMap(res.data[0].DATA));
        }else{
          dispatch(addSiteMap([]));
        }
      });
  }
  useEffect(()=>{
    const currentURL = window.location.href;
    const isInArray = basesitemaparr.includes(currentURL);
    if (isInArray) {
      console.log('Current URL is in the array.');
    } else {
      getSitemapData(currentURL);
    }
  },[window.location.href])
  return (
    <>
        <Header/>
        <Container maxW={'7xl'} p="12">
            <DynamicButton/>
            <Heading as="h1">Terms & Conditions</Heading>
            <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
                <Text as="p" fontSize="lg">
                    Please read these terms and conditions carefully before using our website. By accessing or using our website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, please do not use our website.
                </Text>
                <Heading fontSize="xl">1. General Terms</Heading>  
                <Text as="p" fontSize="lg">
                    1.1 Acceptance: By using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
                </Text>
                <Text as="p" fontSize="lg">
                    1.2 Amendments: We reserve the right to modify, update, or change these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for any changes. Your continued use of the website after any modifications constitutes acceptance of those changes.
                </Text>
                <Text as="p" fontSize="lg">
                    1.3 Age Restriction: You must be at least 18 years old to use our website. If you are under 18, you may use our website only with the involvement of a parent or guardian.
                </Text>
                <Heading fontSize="xl">2. Intellectual Property</Heading>
                <Text as="p" fontSize="lg">
                    2.1 Ownership: All content on our website, including but not limited to text, graphics, logos, images, videos, audio clips, digital downloads, and software, is the property of {companysetting.length > 0 && companysetting[0].DATA.company.Companyinfo.company_name} or its content suppliers and is protected by applicable copyright laws.
                </Text>
                <Text as="p" fontSize="lg">
                    2.2 License: We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our website for personal and non-commercial purposes only. You may not reproduce, modify, distribute, display, or exploit any part of the website without our prior written consent.
                </Text>

                <Heading fontSize="xl">3. Product Information</Heading>
                <Text as="p" fontSize="lg">
                    3.1 Accuracy: We strive to provide accurate and up-to-date information about our products on the website. However, we do not warrant or guarantee the accuracy, completeness, reliability, or timeliness of any product descriptions, pricing, or other content on the website.
                </Text>
                <Text as="p" fontSize="lg">
                    3.2 Availability: The availability of products on our website may change without notice. We reserve the right to discontinue any product at any time.
                </Text>
                <Text as="p" fontSize="lg">
                    3.3 Pricing: Product prices listed on our website are subject to change without notice. The price displayed at the time of purchase is the final price, including any applicable taxes or fees.
                </Text>

                <Heading fontSize="xl">4. Order and Payment</Heading>
                <Text as="p" fontSize="lg">
                    4.1 Order Acceptance: Your placement of an order through our website constitutes an offer to purchase the specified product(s). We reserve the right to accept or decline your order for any reason, including but not limited to product availability, errors in product information, or suspected fraudulent activity.
                </Text>
                <Text as="p" fontSize="lg">
                    4.2 Payment: We accept various forms of payment as indicated on our website. You agree to provide accurate and complete payment information and authorize us to charge the total order amount to the selected payment method.
                </Text>
                <Text as="p" fontSize="lg">
                    4.3 Shipping and Delivery: Shipping and delivery terms are outlined in our separate Shipping Policy, which forms part of these terms and conditions.
                </Text>

                <Heading fontSize="xl">5. Returns, Exchanges, and Cancellations</Heading>
                <Text as="p" fontSize="lg">
                    5.1 Return Policy: Our return policy is outlined in our separate Returns Policy, which forms part of these terms and conditions.
                </Text>
                <Text as="p" fontSize="lg">
                    5.2 Refunds: All non-refundable items are clearly marked and identified as such at the time of purchase, and by completing the purchase transaction, you acknowledge and agree that these items are non-refundable.
                </Text>

                <Heading fontSize="xl">6. Privacy and Data Protection</Heading>
                <Text as="p" fontSize="lg">
                    6.1 Privacy: Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information.
                </Text>
                <Text as="p" fontSize="lg">
                    6.2 Cookies: Our website may use cookies to enhance your browsing experience. By using our website, you consent to the use of cookies in accordance with our Cookie Policy.
                </Text>

                <Heading fontSize="xl">7. Limitation of Liability</Heading>
                <Text as="p" fontSize="lg">
                    7.1 Disclaimer: Our website is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the website's operation or content.
                </Text>

            </VStack>
        </Container>
        <Footer/>
    </>
  )
}

export default TermdandCondition