import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Flex,
  Image,
  Center,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import http from '../http-common';
import { AppGrid } from './ProductGrid/AppGrid';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Footer/Footer';
import { Header } from '../header/Header';
import { changeCategoryLabel } from '../redux/slices/categorySlice';
//#======= Slider
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DynamicButton from './DynamicButton';
import { BrowserView, MobileView } from 'react-device-detect';
import LoadingSpinner from '../spiiner/LoadingSpinner';
import { HiArrowNarrowRight } from 'react-icons/hi'; // Replace with the icon you want to use
import AppHelmet from '../Basic/AppHelmet';
//import { Helmet } from 'react-helmet';

export default function Home() {
  //document.title = 'Home';
  const [slider, setSlider] = useState(null);
  const [selcategory, setSelCategory] = useState([]);
  const [category_arr1, setSelCategory1] = useState([]);
  const [category_arr2, setSelCategory2] = useState([]);
  const [category_arr3, setSelCategory3] = useState([]);
  const [cat3, setCat3] = useState('');
  const [bannerlist, setBannerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const companysetting = useSelector(state => state.company);
  const baseurl = useSelector(state => state.baseurl.baseURL);
  const basehostname = useSelector(state => state.baseurl.basehostname);

  const appmetatitle = " Buy Canvas Paintings,Wall Decor and Gift Articles Online in Ahmedabad, India";
  const appmetadescription = " Buy latest collection at Mur d’art by Hari Gajjar. Shop canvas paintings, wall decor and unique gift articles at the best prices. Customize your artistic choices. Ahmedabad, India";
  const maincolor =
    companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null
      ? companysetting[0].DATA.theme_maincolor
      : '#000';

  const dispatch = useDispatch();
  let navigate = useNavigate();
  async function getProductCategory(text) {
    let url = '/ecommerce_api/getProductCategoryListHomeActive1/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
      })
      .then(res => {
        //console.log(res);
        if (res.data[0].STATUS == 'SUCCESS') {
          //console.log('Home1');
          //console.log(res.data[0].DATA.catprodlist[0].length);
          if (res.data[0].DATA.catprodlist[0].length > 0) {
            setSelCategory1(res.data[0].DATA.catprodlist[0]);
          }
          if (res.data[0].DATA.catprodlist[1].length > 0) {
            setSelCategory2(res.data[0].DATA.catprodlist[1]);
          }
          if (res.data[0].DATA.catprodlist[2].length > 0) {
            setSelCategory3(res.data[0].DATA.catprodlist[2]);
          }
        }
      });
  }

  useEffect(() => {
    dispatch(changeCategoryLabel(''));
    getProductCategory();
  }, []);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: selcategory.length > 6 ? 7 : selcategory.length,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: selcategory.length > 5 ? 6 : selcategory.length,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: selcategory.length > 3 ? 3 : selcategory.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: selcategory.length > 2 ? 2 : selcategory.length,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, ['']);
  useEffect(() => {
    http
      .post(`/ecommerce_api/getProductCategoryList/`, {
        text: '',
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          setSelCategory(res.data[0].DATA.categorylist);
        } else {
          setSelCategory([]);
        }
      })
      .catch(err => {
        console.log(err.message);
      });
  }, ['']);
  useEffect(() => {
    setLoading(true);
    /*const spinner = document.getElementById('spinner');
    if (spinner) {
      spinner.style.display = 'flex';
    }*/
    http
      .post(`/ecommerce_api/getBannerLists/`, {
        text: '',
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          //console.log('Banner Master');
          //console.log(res.data[0].DATA.bannerlists);
          setBannerList(res.data[0].DATA.banner_array);
          /*if (spinner) {
            spinner.style.display = 'none';
          }*/
          setLoading(false);
        } else {
          setBannerList([]);
          /*if (spinner) {
            spinner.style.display = 'none';
          }*/
          setLoading(false);
        }
      })
      .catch(err => {
        //console.log(err.message);
        /*if (spinner) {
          spinner.style.display = 'none';
        }*/
        setLoading(false);
      });
  }, ['']);
  return (
    !loading ? (
      <>
        {basehostname == 'murdartbyhari.ondemandcrm.co' &&
        <AppHelmet title={appmetatitle} description={appmetadescription} />
        }
        <Header />
        <MobileView>
          <DynamicButton />
        </MobileView>
        <Box position={'relative'} width={'full'} overflow={'hidden'}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            stopOnHover={false}
            showIndicators={bannerlist.length > 1 ? true : false}
          >
            {bannerlist.map((bann, index) => (
              <>
                {window.location.hostname == 'vanillathecakeshop.in' ? (
                  <>
                    <BrowserView>
                      <div
                        onClick={() =>
                          window.open(
                            `${bann.url}`,
                            '_self'
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <Image
                          src={`${bann.photo_path}`}
                        />
                      </div>
                    </BrowserView>
                    <MobileView>
                      <div
                        onClick={() =>
                          window.open(
                            `${bann.url}`,
                            '_self'
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <Image
                          src={`${baseurl}/public/ecommerce_portal/cake_banner_mobile.png`}
                        />
                      </div>
                    </MobileView>
                  </>
                ) : (
                  <div
                    onClick={() =>
                      window.open(`${bann.url}`, '_self')
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Image
                      src={`${bann.photo_path}`}
                    />
                  </div>
                )}
              </>
            ))}
          </Carousel>
        </Box>

        {selcategory.length > 0 && (
          <Box>
            <Slider {...settings}>
              {selcategory.length > 0 &&
                selcategory.map(e => {
                  return (
                    <div key={e.value}>
                      {/* /ecommerce Remove  */}
                      <Box
                        p="6"
                        key={e.value}
                        cursor="pointer"
                        onClick={() => {
                          navigate(`/filter/${e.label.replace(/\s+/g, '-')}`);
                        }}
                      >
                        <Center>
                          <Image
                            src={`${e.full_image_path}`}
                            alt={e.label}
                            borderRadius="full"
                            boxSize="100px"
                          />
                        </Center>
                        <Center mt={3}>
                          <Text textAlign="center">{e.label}</Text>
                        </Center>
                      </Box>
                    </div>
                  );
                })}
            </Slider>
          </Box>
        )}
        {category_arr1.length > 0 && (
          <Box as={Container} maxW="7xl" mt={2} p={4}>
            <Flex pl={12} pr={12} fontWeight="600" color={maincolor} align="center" display="inline-flex" cursor="pointer" onClick={()=>navigate(`/filter/${category_arr1[0].category_name.replace(/\s+/g, '-')}`)}>
              <Box fontSize="2xl" mr="15px">{category_arr1[0].category_name}</Box>
              <HiArrowNarrowRight fontSize="28px" />
            </Flex>
            {category_arr1.length > 0 ? (
              <AppGrid products={category_arr1.slice(0, 4)} />
            ) : (
              <></>
            )}
          </Box>
        )}
        {category_arr2.length > 0 && (
          <Box as={Container} maxW="7xl" mt={2} p={4}>
            <Flex pl={12} pr={12} fontWeight="600" color={maincolor} align="center" display="inline-flex" cursor="pointer" onClick={()=>navigate(`/filter/${category_arr2[0].category_name.replace(/\s+/g, '-')}`)}>
              <Box fontSize="2xl" mr="15px">{category_arr2[0].category_name}</Box>
              <HiArrowNarrowRight fontSize="28px"  />
            </Flex>
            {category_arr2.length > 0 ? (
              <AppGrid products={category_arr2.slice(0, 4)} />
            ) : (
              <></>
            )}
          </Box>
        )}
        {companysetting.length > 0 && (
          <Box position={'relative'} width={'full'} overflow={'hidden'}>
            {companysetting[0].DATA.company.Companyinfo.image10 != null && (
              <>
                {window.location.hostname == 'vanillathecakeshop.in' ? (
                  <>
                    <BrowserView>
                      <Image
                        alt={'product image'}
                        src={`${baseurl}/viewimage/getcompany/${companysetting[0].DATA.company.Companyinfo.image10}`}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                      />
                    </BrowserView>
                    <MobileView>
                      <Image
                        alt={'product image'}
                        src={`${baseurl}/public/ecommerce_portal/sec_cake_banner_mobile.png`}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                      />
                    </MobileView>
                  </>
                ) : (
                  <Image
                    onClick={()=>window.open(`${companysetting[0].DATA.home_second_banner_image_url}`, '_self')}
                    cursor="pointer"
                    alt={'product image'}
                    src={`${baseurl}/viewimage/getcompany/${companysetting[0].DATA.company.Companyinfo.image10}`}
                    fit={'cover'}
                    align={'center'}
                    w={'100%'}
                    h={'100%'}
                  />
                )}
              </>
            )}
          </Box>
        )}
        {category_arr3.length > 0 && (
          <Box as={Container} maxW="7xl" mt={2} p={4}>
            <Flex pl={12} pr={12} fontWeight="600" color={maincolor} align="center" display="inline-flex" cursor="pointer" onClick={()=>navigate(`/filter/${category_arr3[0].category_name.replace(/\s+/g, '-')}`)}>
              <Box fontSize="2xl" mr="15px">{category_arr3[0].category_name}</Box>
              <HiArrowNarrowRight fontSize="28px"  />
            </Flex>
            {category_arr3.length > 0 ? (
              <AppGrid products={category_arr3.slice(0, 4)} />
            ) : (
              <></>
            )}
          </Box>
        )}
        <Footer />
      </>
    ) : (<><LoadingSpinner/></>)
  );
}
