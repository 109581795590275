import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
  Select,
  useDisclosure,Button,FormLabel
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import { MdFilterList } from 'react-icons/md'
import { CheckboxFilter } from './CheckboxFilter'
import { ColorPicker } from './ColorPicker'
import { FilterDrawer } from './FilterDrawer'
import { PriceRangePicker } from './PriceRangePicker'
import { SizePicker } from './SizePicker'
import { SortbySelect } from './SortBySelect'
import { blueFilters, colorFilter, sizeFilter } from './_data'
import AttributeMaster from '../AttributeMaster';
import { useForm, useFormContext, FormContext } from "react-hook-form";
import http from "../../http-common";

export const MobileFilter = ({companysetting,attributes,category,setSelProducts,brandMaster}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { register,handleSubmit,setValue,getValues,watch,reset, formState: { errors } } = useForm();
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [selectBrand, setSelectBrand] = useState(0);
  const onSubmit = (data) => {
    //console.log(data);
    http.post(`/ecommerce_api/getProductFilterSearchList/`, {
      moredata: data,
      category_name: category,
      minprice:minVal,
      maxprice:maxVal,
      type:'D',
      brand_id:selectBrand
    }).then(res => {
      if (res.data[0].STATUS == "SUCCESS") {
          setSelProducts(res.data[0].DATA.products);
          onClose();
          reset();
      } else {
          setSelProducts([]);
          onClose();
          reset();
      }
    }).catch(err => {
      //console.log("Something went wrong", "error");
      reset();
    });
  }
  return (
    <>
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <HStack
          as="button"
          fontSize="sm"
          type="button"
          px="3"
          py="1"
          onClick={onOpen}
          borderWidth="1px"
          rounded="md"
        >
          <Icon as={MdFilterList} />
          <Text>Filters</Text>
        </HStack>
        <SortbySelect width="120px" defaultValue="23" placeholder="Sort" />
      </Flex>
      <FilterDrawer isOpen={isOpen} onClose={onClose} >
        <Stack spacing="6" divider={<StackDivider />}>
          <form onSubmit={handleSubmit(onSubmit)} >
          {brandMaster.brands.length  && 
          <Stack spacing="1">
            <FormLabel mt={2} fontWeight="semibold" as="legend" mb="0">
              Brand
            </FormLabel>
            <HStack > 
              <Select placeholder='Select option' size='sm' onChange={(e)=>setSelectBrand(e.target.value)}>
              {(brandMaster.brands.length > 0) && brandMaster.brands.map((bd) =>
                  <option value={bd.value}>{bd.label}</option>
                )}
              </Select>
            </HStack>
          </Stack>
          }
          
          {(companysetting.length > 0) && 
              <AttributeMaster  spacing="3"  register={register} attributes={attributes} />
          }
          <Stack spacing="1">
            <FormLabel mt={2} fontWeight="semibold" as="legend" mb="0">
              Price range
            </FormLabel>
            <HStack spacing="2">
              <Input type="number" placeholder="500" onBlur={(e)=>{setMinVal(e.target.value > 0 ? e.target.value : 0)}}/>
              <Input type="number" placeholder="1,000" onBlur={(e)=>{setMaxVal(e.target.value > 0 ? e.target.value : 0)}}/>
            </HStack>
          </Stack>
          <HStack mt={6}>
            <Button  borderRadius={0} type="submit" colorScheme='blue' size='md'>Search</Button>
            <Button borderRadius={0}  onClick={(e)=>{window.location.reload();}} ml={4} colorScheme='green' size='md'>Reset</Button>
          </HStack>
          </form>
        </Stack>
      </FilterDrawer>
    </>
  )
}

