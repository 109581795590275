import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';
import { Header } from '../header/Header';
import { changeCategoryLabel } from '../redux/slices/categorySlice';
import { Container,Box, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { addSiteMap } from '../redux/slices/sitemapSlice';
import Aboutus from '../component/Images/Aboutus.jpg';
import http from '../http-common';
const About = () => {
  const dispatch = useDispatch();
  const companysetting = useSelector(state => state.company);
  const basesitemaparr = useSelector(state => state.sitemap.sitemap);
  let compdesc = companysetting.length > 0 ? companysetting[0].DATA.company.Companyinfo.short_description : "";
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeCategoryLabel(''));
    const spinner = document.getElementById('spinner');
    if (spinner) {
      setTimeout(() => {
        spinner.style.display = 'none';
      }, 1500);
    }
  }, ['']);
  async function getSitemapData(current_url){
    let url = '/ecommerce_api/saveSitemap/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        url: current_url,
      })
      .then(res => {
        if(res.data[0].STATUS == 'SUCCESS'){
          dispatch(addSiteMap(res.data[0].DATA));
        }else{
          dispatch(addSiteMap([]));
        }
      });
  }
  useEffect(()=>{
    const currentURL = window.location.href;
    const isInArray = basesitemaparr.includes(currentURL);
    if (isInArray) {
      console.log('Current URL is in the array.');
    } else {
      getSitemapData(currentURL);
    }
  },[window.location.href])
  return (
    <>
      <Helmet>
        <meta name="description" content="This is the meta description." />
      </Helmet>
      <Header />
      <Box as={Container} maxW="7xl" mt={2} p={4} minH={'750px'}>
      
      <Box p={2}>
        <Heading as="h1" size="xl" textAlign="center" m={6}>
          About Us
        </Heading>
      </Box>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={6}
        m={{ base: 3, md: 10 }}
      >
        <GridItem>
            <Image src={Aboutus} alt="about us" />
        </GridItem>
        <GridItem>
            <Text
              fontSize="20px"
              fontFamily="Arial, Helvetica, sans-serif"
              as="pre"
              whiteSpace="pre-wrap"
            >
              {`${compdesc}`}
          </Text>
        </GridItem>
      </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default About;
