// src/redux/sitemapSlice.js
import { createSlice } from '@reduxjs/toolkit';
const sitemapSlice = createSlice({
    name: 'sitemap',
    initialState: {
        sitemap: [],
    },
    reducers: {
        addSiteMap: (state, action) => {
            state.sitemap = action.payload ;
        },
    },
});

export const sitemapReducer = sitemapSlice.reducer;
export const {
    addSiteMap,
} = sitemapSlice.actions;