import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import DynamicButton from '../component/DynamicButton';
import HeaderCheckout from '../header/HeaderCheckout';
import {
  Box,
  Container,
  Text,
  Stack,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Card,
  CardHeader,
  CardBody,
  RadioGroup,
  Radio,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CardFooter,
  Divider,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import http from '../http-common';
import { Address } from './Address';
export const CheckoutPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const companysetting = useSelector(state => state.company);
  const cart = useSelector(state => state.cart.cart);
  //console.log("Checkout Page");console.log(cart);
  const maincolor =
    companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null
      ? companysetting[0].DATA.theme_maincolor
      : '#7c1c1c';
  const fontcolor =
    companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null
      ? companysetting[0].DATA.theme_fontcolor
      : '#FFF';
  const [contact_add, setContactAddress] = useState([]);
  const [value, setValue] = useState();
  const [address, setAddress] = useState([]);
  const [accordionIndex, setAccordionIndex] = useState(0);

  const getTotalQuantity = () => {
    let total = 0;
    if (cart.length > 0) {
      cart.forEach(item => {
        total += item.quantity;
      });
    }
    return total;
  };
  return (
    <>
      <HeaderCheckout />
      <Box as={Container} maxW="7xl" mt={2} p={12} minH={'780px'}>
        <Heading fontSize="xl" fontWeight="extrabold">
          {companysetting.length > 0 &&
          companysetting[0].DATA.label_title_cart != null
            ? companysetting[0].DATA.label_title_cart
            : 'Shopping Cart'}{' '}
          ({getTotalQuantity() || 0} items)
        </Heading>
        <TableContainer mt={10}>
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Product Name</Th>
                <Th isNumeric>Qty</Th>
                <Th isNumeric>Rate</Th>
                <Th isNumeric>Tax (%)</Th>
                <Th isNumeric>Tax Amount</Th>
                <Th isNumeric>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>inches</Td>
                <Td>millimetres (mm)</Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4 </Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4</Td>
              </Tr>
              <Tr>
                <Td>inches</Td>
                <Td>millimetres (mm)</Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4 </Td>
                <Td isNumeric>25.4</Td>
                <Td isNumeric>25.4</Td>
              </Tr>
            </Tbody>
            <Tfoot>
                <Tr>
                    <Th colSpan={6}>Total Amount</Th>
                    <Th isNumeric>100</Th>
                </Tr>
                <Tr>
                    <Th colSpan={6}>Shipping Charge</Th>
                    <Th isNumeric>50</Th>
                </Tr>
                <Tr>
                    <Th colSpan={6}>Grand Total</Th>
                    <Th isNumeric>150</Th>
                </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </>
  );
};
