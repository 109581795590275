import { useForm } from "react-hook-form";
import http from "../http-common";

function PaymentForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  let payFormResponse = JSON.parse(localStorage.getItem('payFormResponse')) || null;
  let prefillData = null; 
  // let pfresponse = null;
  const onSubmit = async data => {
    let url = '/ecommerce_api/onepaytransaction/';
    const res = await http.post(url,{
        AUTHORIZEKEY: "",
        transaction:data
    }).then((res) =>{
      if(res.data[0].STATUS == "SUCCESS") {
        payFormResponse = res.data[0].payFormResponse;
        localStorage.setItem('payFormResponse', JSON.stringify(payFormResponse));
        window.location.reload(); 
      }
    });
  };

  if(payFormResponse) {
    prefillData = payFormResponse;
    //console.log("Record Payment");console.log(prefillData.merchantId);console.log(prefillData.postUrl);console.log(prefillData.reqData);
    setTimeout(() => {
      localStorage.removeItem('payFormResponse');
      const pfForm = document.getElementById("payment-form");
      pfForm.submit();
    }, 1000);

    return (
      <div className="App row align-items-center justify-content-center full-height">
        <div className="col-md-4">
            <form method="POST" className="d-none" id="payment-form" action={ prefillData?.postUrl }>
              <div><input defaultValue={prefillData?.merchantId} type="text" {...register("merchantId", {required: true})} /></div>
              <div><input defaultValue={prefillData?.reqData} type="text" {...register("reqData", {required: true})} /></div>
          
              <input type="submit" />
            </form>
            <div className="text-center">
            <h4>Please wait...</h4>
            <div>Do not refresh or press any</div>
          </div>
        </div>
      </div>
    );
  }
  else {
    prefillData = {
      txnId: 'P' + new Date().getTime(),
      amount: "99.00",
      dateTime: '2021-11-01 12:20:33',
      custMobile: '9876588877',
      custMail: 'devtest@wizgle.com',
      channelId: '0',
      txnType: 'DIRECT',
      returnURL: 'http://localhost:4200/api/v1/transaction/response',
      productId: 'DEFAULT',
      isMultiSettlement: '0',
      instrumentId: 'NA',
      udf1: 'NA',
      udf2: 'NA',
      udf3: 'NA',
      udf4: 'NA',
      udf5: 'NA',
      cardType: 'NA',
      cardDetails: 'NA',
    };

    return (
      <div className="App container row align-items-center justify-content-center">
        <div className="col-md-7">
          <h3>Payment Form (React)</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3"><label>Merchant Order No.</label><input className="form-control" defaultValue={prefillData.txnId} type="text" placeholder="Merchant Order No." {...register("txnId", {required: true})} /></div>
            <div className="form-group mb-3"><label>Amount</label><input className="form-control" defaultValue={prefillData.amount} type="text" placeholder="Amount" {...register("amount", {required: true})} /></div>
            <div className="form-group mb-3"><label>Date and Time</label><input className="form-control" defaultValue={prefillData.dateTime} type="text" placeholder="Date and Time" {...register("dateTime", {required: true})} /></div>
            <div className="form-group mb-3"><label>Cust Mail</label><input className="form-control" defaultValue={prefillData.custMail} type="text" placeholder="Cust Mail" {...register("custMail", {required: true})} /></div>
            <div className="form-group mb-3"><label>Cust Mobile</label><input className="form-control" defaultValue={prefillData.custMobile} type="text" placeholder="Cust Mobile" {...register("custMobile", {required: true})} /></div>
            <div className="form-group mb-3"><label>UDF 1</label><input className="form-control" defaultValue={prefillData.udf1} type="text" placeholder="UDF 1" {...register("udf1", {required: true})} /></div>
            <div className="form-group mb-3"><label>UDF 2</label><input className="form-control" defaultValue={prefillData.udf2} type="text" placeholder="UDF 2" {...register("udf2", {required: true})} /></div>
            <div className="form-group mb-3"><label>Return URL</label><input className="form-control" defaultValue={prefillData.returnURL} type="text" placeholder="Return URL" {...register("returnURL", {required: true})} /></div>
            <div className="form-group mb-3"><label>Product Id</label><input className="form-control" defaultValue={prefillData.productId} type="text" placeholder="Product Id" {...register("productId", {required: true})} /></div>
            <div className="form-group mb-3"><label>Channel Id</label><input className="form-control" defaultValue={prefillData.channelId} type="text" placeholder="Channel Id" {...register("channelId", {required: true})} /></div>
            <div className="form-group mb-3"><label>isMultiSettlement</label><input className="form-control" defaultValue={prefillData.isMultiSettlement} type="text" placeholder="isMultiSettlement" {...register("isMultiSettlement", {required: true})} /></div>
            <div className="form-group mb-3"><label>Transaction Type:</label><input className="form-control" defaultValue={prefillData.txnType} type="text" placeholder="Transaction Type:" {...register("txnType", {required: true})} /></div>
            <div className="form-group mb-3"><label>Instrument Id</label><input className="form-control" defaultValue={prefillData.instrumentId} type="text" placeholder="Instrument Id" {...register("instrumentId", {required: true})} /></div>
            <div className="form-group mb-3"><label>UDF 3</label><input className="form-control" defaultValue={prefillData.udf3} type="text" placeholder="UDF 3" {...register("udf3", {required: true})} /></div>
            <div className="form-group mb-3"><label>UDF 4</label><input className="form-control" defaultValue={prefillData.udf4} type="text" placeholder="UDF 4" {...register("udf4", {required: true})} /></div>
            <div className="form-group mb-3"><label>UDF 5</label><input className="form-control" defaultValue={prefillData.udf5} type="text" placeholder="UDF 5" {...register("udf5", {required: true})} /></div>
            <div className="form-group mb-3"><label>Card Details</label><input className="form-control" defaultValue={prefillData.cardDetails} type="text" placeholder="Card Details" {...register("cardDetails", {required: true})} /></div>
            <div className="form-group mb-3"><label>Card Type</label><input className="form-control" defaultValue={prefillData.cardType} type="text" placeholder="Card Type" {...register("cardType", {required: true})} /></div>

            <input className="btn btn-primary" type="submit" />
          </form>
        </div>
      </div>
    );
  }
}
export default PaymentForm;